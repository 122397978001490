import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{` components are a suite of components which can be combined together to make several different variations of our GitHub select menu. At it's most basic form, a select menu is comprised of a `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{` wrapper, which contains a `}<inlineCode parentName="p">{`summary`}</inlineCode>{` component of your choice and a `}<inlineCode parentName="p">{`Select.Modal`}</inlineCode>{` which contains the select menu content. Use `}<inlineCode parentName="p">{`SelectMenu.List`}</inlineCode>{` to wrap items in the select menu, and `}<inlineCode parentName="p">{`SelectMenu.Item`}</inlineCode>{` to wrap each item.`}</p>
    <p>{`Several additional components exist to provide even more functionality: `}<inlineCode parentName="p">{`SelectMenu.Header`}</inlineCode>{`, `}<inlineCode parentName="p">{`SelectMenu.Filter`}</inlineCode>{`, `}<inlineCode parentName="p">{`SelectMenu.Tabs`}</inlineCode>{`, `}<inlineCode parentName="p">{`SelectMenu.TabPanel`}</inlineCode>{` `}<inlineCode parentName="p">{`SelectMenu.Footer`}</inlineCode>{` and `}<inlineCode parentName="p">{`SelectMenu.Divider`}</inlineCode>{`.`}</p>
    <h2>{`Basic Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SelectMenu>
  <Button as="summary">Projects</Button>
  <SelectMenu.Modal>
    <SelectMenu.Header>Projects</SelectMenu.Header>
    <SelectMenu.List>
      <SelectMenu.Item href="#">Uchiru Components bugs</SelectMenu.Item>
      <SelectMenu.Item href="#">Uchiru Components roadmap</SelectMenu.Item>
      <SelectMenu.Item href="#"> Project 3</SelectMenu.Item>
      <SelectMenu.Item href="#">Project 4</SelectMenu.Item>
    </SelectMenu.List>
  </SelectMenu.Modal>
</SelectMenu>
`}</code></pre>
    <h2>{`SelectMenu`}</h2>
    <p>{`Main wrapper component for select menu.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SelectMenu>
  {/* all other sub components are wrapped here*/}
</SelectMenu>
`}</code></pre>
    <h3>{`System props`}</h3>
    <p>{`SelectMenu components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`initialTab`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If using the `}<inlineCode parentName="td">{`SelectMenu.Tabs`}</inlineCode>{` component, you can use this prop to change the tab shown on open. By default, the first tab will be used.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`SelectMenu.MenuContext`}</h2>
    <p>{`SelectMenu.MenuContext is a `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html#reactcreatecontext"
      }}>{`context object`}</a>{` that exposes some helpful state values to be used via `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#usecontext"
      }}><inlineCode parentName="a">{`React.useContext`}</inlineCode></a>{` in consuming applications.  SelectMenu.MenuContext can only be used in components that are already wrapped in a `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{` as `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{` contains the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html#contextprovider"
      }}>{`context provider`}</a>{`.`}</p>
    <h3>{`Values available on MenuContext`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`selectedTab`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The currently selected tab`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`setSelectedTab`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`function`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used to update the currently selected tab state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`open`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`State for open/closed status of the menu modal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`setOpen`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`function`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used to update the `}<inlineCode parentName="td">{`open`}</inlineCode>{` state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`initialTab`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Mostly used internally to pass down which tab should be set to open by default. To change this value use the `}<inlineCode parentName="td">{`initialTab`}</inlineCode>{` prop on `}<inlineCode parentName="td">{`SelectMenu`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Example Usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {SelectMenu, Button} from \`@uchiru/components\`
import React, {useContext} from 'react'

const MyMenu = () => {
  <SelectMenu>
    <MyButton as="summary" />
    <SelectMenu.Modal>
      content
    </SelectMenu.Modal>
  </SelectMenu>
}

// note that we can only use the context in components that are already wrapped by SelectMenu (and thus the Context.Provider)
const MyButton = () => {
  const menuContext = useContext(SelectMenu.MenuContext);

  return (
    <Button as="summary">{menuContext.open ? 'Open' : 'Closed'}</Button>
  )
}
`}</code></pre>
    <h2>{`SelectMenu.Modal`}</h2>
    <p>{`Used to wrap the content in a `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SelectMenu.Modal>
  {/* all menu content is wrapped in the modal*/}
</SelectMenu.Menu>
`}</code></pre>
    <h3>{`Right-aligned modal`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`align='right'`}</inlineCode>{` prop to align the modal to the right. Note that this only modifies alignment for the modal, and not the SelectMenu itself. You will need to wrap the SelectMenu in a relatively positioned element for this to work properly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Relative display="flex" justifyContent="flex-end">
  <SelectMenu>
    <Button as="summary">Projects</Button>
    <SelectMenu.Modal align="right">
      <SelectMenu.Header>Projects</SelectMenu.Header>
      <SelectMenu.List>
        <SelectMenu.Item href="#">Uchiru Components bugs</SelectMenu.Item>
        <SelectMenu.Item href="#">Uchiru Components roadmap</SelectMenu.Item>
        <SelectMenu.Item href="#"> Project 3</SelectMenu.Item>
        <SelectMenu.Item href="#">Project 4</SelectMenu.Item>
      </SelectMenu.List>
    </SelectMenu.Modal>
  </SelectMenu>
</Relative>
`}</code></pre>
    <h3>{`System Props`}</h3>
    <p>{`SelectMenu.Modal components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`align`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`'left'`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use `}<inlineCode parentName="td">{`right`}</inlineCode>{` to align the select menu to the right`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`SelectMenu.List`}</h2>
    <p>{`Used to wrap the select menu list content.  All menu items `}<strong parentName="p">{`must`}</strong>{` be wrapped in a SelectMenu.List in order for the accessbility keyboard handling to function properly. If you are using the `}<inlineCode parentName="p">{`SelectMenu.TabPanel`}</inlineCode>{` you do not need to provide a `}<inlineCode parentName="p">{`SelectMenu.List`}</inlineCode>{` as that component renders a `}<inlineCode parentName="p">{`SelectMenu.List`}</inlineCode>{` as a wrapper.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SelectMenu.List>
  {/* all menu  list items are wrapped in the list*/}
</SelectMenu.List>
`}</code></pre>
    <h3>{`System Props`}</h3>
    <p>{`SelectMenu.List components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <p>{`SelectMenu.List components do not get any additional props besides system props.`}</p>
    <h2>{`SelectMenu.Item`}</h2>
    <p>{`Individual items in a select menu. SelectMenu.Item renders an anchor tag by default, you'll need to make sure to provide the appropriate `}<inlineCode parentName="p">{`href`}</inlineCode>{`. `}</p>
    <p>{`You can use a `}<inlineCode parentName="p">{`button`}</inlineCode>{` tag instead by utilizing the `}<a parentName="p" {...{
        "href": "/about/core-concepts#the-as-prop"
      }}><inlineCode parentName="a">{`as`}</inlineCode>{` prop`}</a>{`. Be sure to consider `}<a parentName="p" {...{
        "href": "https://marcysutton.com/links-vs-buttons-in-modern-web-applications"
      }}>{`which HTML element is the right choice`}</a>{` for your usage of the component. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SelectMenu.Item href="/link/to/thing" selected={true}>
  {/* wraps an individual list item*/}
</SelectMenu.Item>
`}</code></pre>
    <h3>{`System Props`}</h3>
    <p>{`SelectMenu.Item components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`selected`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used to apply styles to the selected items in the list.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onClick`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`function`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function called when item is clicked. By default we also close the menu when items are clicked. If you would like the menu to stay open, pass an `}<inlineCode parentName="td">{`e.preventDefault()`}</inlineCode>{` to your onClick handler.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`SelectMenu.Filter`}</h2>
    <p>{`Use a `}<inlineCode parentName="p">{`SelectMenu.Filter`}</inlineCode>{` to add a filter UI to your select menu. Users are expected to implement their own filtering and manage the state of the `}<inlineCode parentName="p">{`value`}</inlineCode>{` prop on the input. This gives users more flexibility over the type of filtering and type of content passed into each select menu item.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SelectMenu>
  <Button as="summary">Projects</Button>
  <SelectMenu.Modal>
    <SelectMenu.Header>Filter by Project</SelectMenu.Header>
    <SelectMenu.Filter placeholder="Filter projects" value="" aria-label="Filter Projects"/>
    <SelectMenu.List>
      <SelectMenu.Item href="#">Uchiru Components bugs</SelectMenu.Item>
      <SelectMenu.Item href="#">Uchiru Components roadmap</SelectMenu.Item>
      <SelectMenu.Divider>More Options</SelectMenu.Divider>
      <SelectMenu.Item href="#"> Project 3</SelectMenu.Item>
      <SelectMenu.Item href="#">Project 4</SelectMenu.Item>
    </SelectMenu.List>
  </SelectMenu.Modal>
</SelectMenu>
`}</code></pre>
    <h3>{`System Props`}</h3>
    <p>{`SelectMenu.Filter components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <p>{`SelectMenu.Filter components receive all the props that the `}<a parentName="p" {...{
        "href": "/atoms/TextInput"
      }}>{`TextInput`}</a>{` component gets.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Users of this component must provide a value for the filter input that is managed in the consuming application`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`SelectMenu.Tabs`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`SelectMenu.Tabs`}</inlineCode>{` to wrap the the tab navigation and `}<inlineCode parentName="p">{`SelectMenu.Tab`}</inlineCode>{` for each tab in the navigation.`}</p>
    <p><inlineCode parentName="p">{`SelectMenu.TabPanel`}</inlineCode>{` should wrap each corresponding panel for each of the tabs. The `}<inlineCode parentName="p">{`tabName`}</inlineCode>{` prop for each `}<inlineCode parentName="p">{`SelectMenu.TabPanel`}</inlineCode>{` must match the name provided in the `}<inlineCode parentName="p">{`tabName`}</inlineCode>{` prop on `}<inlineCode parentName="p">{`SelectMenu.Tab`}</inlineCode>{`.`}</p>
    <p>{`To set one of the tabs to be open by default, use `}<inlineCode parentName="p">{`initialTab`}</inlineCode>{` on the main `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{` component. Otherwise, the first tab will be shown by default.`}</p>
    <p>{`Each `}<inlineCode parentName="p">{`Select.Menu`}</inlineCode>{` tab will need to have an `}<inlineCode parentName="p">{`index`}</inlineCode>{` prop. The first tab should be at index `}<inlineCode parentName="p">{`0`}</inlineCode>{`, the second at index `}<inlineCode parentName="p">{`1`}</inlineCode>{` and so forth. The `}<inlineCode parentName="p">{`index`}</inlineCode>{` prop is used to show the first tab by default.`}</p>
    <p>{`If you need access to the selected tab state, you can find it in the MenuContext object exported from `}<inlineCode parentName="p">{`SelectMenu`}</inlineCode>{` as `}<inlineCode parentName="p">{`MenuContext.selectedTab`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SelectMenu>
  <Button as="summary">Projects</Button>
  <SelectMenu.Modal>
    <SelectMenu.Header>Projects</SelectMenu.Header>
    <SelectMenu.Tabs>
      <SelectMenu.Tab index={0} tabName="Repository"/>
      <SelectMenu.Tab index={1} tabName="Organization"/>
    </SelectMenu.Tabs>
    <SelectMenu.TabPanel tabName="Repository">
      <SelectMenu.Item href="#">Uchiru Components bugs</SelectMenu.Item>
      <SelectMenu.Item href="#">Uchiru Components roadmap</SelectMenu.Item>
      <SelectMenu.Item href="#"> Project 3</SelectMenu.Item>
      <SelectMenu.Item href="#">Project 4</SelectMenu.Item>
    </SelectMenu.TabPanel>
    <SelectMenu.TabPanel tabName="Organization">
      <SelectMenu.Item href="#"> Project 2</SelectMenu.Item>
    </SelectMenu.TabPanel>
    <SelectMenu.Footer>Showing 3 of 3</SelectMenu.Footer>
  </SelectMenu.Modal>
</SelectMenu>
`}</code></pre>
    <h3>{`System Props`}</h3>
    <p>{`SelectMenu.Tabs components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <p>{`SelectMenu.Tabs components do not get any additional props besides system props.`}</p>
    <h2>{`SelectMenu.Tab`}</h2>
    <p>{`Used for each individual tab inside of a `}<inlineCode parentName="p">{`SelectMenu.Tabs`}</inlineCode>{`. Be sure to set the `}<inlineCode parentName="p">{`index`}</inlineCode>{` prop to correspond to the order the tab is in. The `}<inlineCode parentName="p">{`tabName`}</inlineCode>{` prop should correspond to the `}<inlineCode parentName="p">{`tabName`}</inlineCode>{` set on the `}<inlineCode parentName="p">{`SelectMenu.TabPanel`}</inlineCode>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` prop is optional and can be used for any events or data fetching you might need to trigger on tab clicks.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SelectMenu.Tab index={0} tabName="Repository"/>
<SelectMenu.Tab index={1} tabName="Organization"/>
`}</code></pre>
    <h3>{`System Props`}</h3>
    <p>{`SelectMenu.Tab components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tabName`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used to identify the corresponding tab. Must match the string used in the `}<inlineCode parentName="td">{`tabs`}</inlineCode>{` array in the `}<inlineCode parentName="td">{`SelectMenu.Tabs`}</inlineCode>{` component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`index`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The index at which the tab is in the list of tabs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onClick`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function to be called when the tab is clicked. Optional.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`SelectMenu.TabPanel`}</h2>
    <p>{`Wraps the content for each tab. Make sure to use the `}<inlineCode parentName="p">{`tabName`}</inlineCode>{` prop to identify each tab panel with the correct tab in the tab navigation.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: SelectMenu.TabPanel wraps content in a SelectMenu.List, so adding a SelectMenu.List manually is not necessary.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SelectMenu.TabPanel tabName="Repository">
  {/* Wraps content for each tab */}
</SelectMenu.TabPanel>
`}</code></pre>
    <h3>{`System Props`}</h3>
    <p>{`SelectMenu.TabPanel components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tabName`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used to identify the corresponding tab. Must match the string used in the `}<inlineCode parentName="td">{`tabs`}</inlineCode>{` array in the `}<inlineCode parentName="td">{`SelectMenu.Tabs`}</inlineCode>{` component.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`SelectMenu.Divider`}</h2>
    <p>{`Use a `}<inlineCode parentName="p">{`SelectMenu.Divider`}</inlineCode>{` to add information between items in a `}<inlineCode parentName="p">{`SelectMenu.List`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SelectMenu>
  <Button as="summary">Projects</Button>
  <SelectMenu.Modal>
    <SelectMenu.Header>Projects</SelectMenu.Header>
    <SelectMenu.List>
      <SelectMenu.Item href="#">Uchiru Components bugs</SelectMenu.Item>
      <SelectMenu.Item href="#">Uchiru Components roadmap</SelectMenu.Item>
      <SelectMenu.Divider>More Options</SelectMenu.Divider>
      <SelectMenu.Item href="#"> Project 3</SelectMenu.Item>
      <SelectMenu.Item href="#">Project 4</SelectMenu.Item>
    </SelectMenu.List>
  </SelectMenu.Modal>
</SelectMenu>
`}</code></pre>
    <h3>{`System Props`}</h3>
    <p>{`SelectMenu.Divder components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <p>{`SelectMenu.Divider components do not get any additional props besides system props.`}</p>
    <h2>{`SelectMenu.Footer`}</h2>
    <p>{`Use a `}<inlineCode parentName="p">{`SelectMenu.Footer`}</inlineCode>{` to add content to the bottom of the select menu.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SelectMenu>
  <Button as="summary">Projects</Button>
  <SelectMenu.Modal>
    <SelectMenu.Header>Projects</SelectMenu.Header>
    <SelectMenu.List>
      <SelectMenu.Item href="#">Uchiru Components bugs</SelectMenu.Item>
      <SelectMenu.Item href="#">Uchiru Components roadmap</SelectMenu.Item>
      <SelectMenu.Item href="#"> Project 3</SelectMenu.Item>
      <SelectMenu.Item href="#">Project 4</SelectMenu.Item>
      <SelectMenu.Footer>Use ⌥ + click/return to exclude labels.</SelectMenu.Footer>
    </SelectMenu.List>
  </SelectMenu.Modal>
</SelectMenu>
`}</code></pre>
    <h3>{`System Props`}</h3>
    <p>{`SelectMenu.Footer components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <p>{`SelectMenu.Footer components do not get any additional props besides system props.`}</p>
    <h2>{`SelectMenu.Header`}</h2>
    <p>{`Use a `}<inlineCode parentName="p">{`SelectMenu.Header`}</inlineCode>{` to add a header to the top of the select menu content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SelectMenu>
  <Button as="summary">Projects</Button>
  <SelectMenu.Modal>
    <SelectMenu.Header>Projects</SelectMenu.Header>
    <SelectMenu.List>
      <SelectMenu.Item href="#">Uchiru Components bugs</SelectMenu.Item>
      <SelectMenu.Item href="#">Uchiru Components roadmap</SelectMenu.Item>
      <SelectMenu.Item href="#"> Project 3</SelectMenu.Item>
      <SelectMenu.Item href="#">Project 4</SelectMenu.Item>
      <SelectMenu.Footer>Use ⌥ + click/return to exclude labels.</SelectMenu.Footer>
    </SelectMenu.List>
  </SelectMenu.Modal>
</SelectMenu>
`}</code></pre>
    <h3>{`System Props`}</h3>
    <p>{`SelectMenu.Header components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h3>{`Component Props`}</h3>
    <p>{`SelectMenu.Header components do not get any additional props besides system props.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      